.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* App.css */
/* body {
  font-family: Arial, sans-serif;
  background: linear-gradient(135deg, #a2c2e9, #f5f7fa);
  height: 100vh;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.glassmorphic-form, .glassmorphic-result {
  background: rgba(255, 255, 255, 0.25);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 20px;
  width: 100%;
}

.glassmorphic-form h1, .glassmorphic-result h2 {
  color: #333;
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.form-group input, .form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.5);
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #0056b3;
}

.whatsapp-button {
  background-color: #25D366;
  margin-left: 20px;
}

.whatsapp-button:hover {
  background-color: #128C7E;
}


/* 
form design */



/* Glassmorphic form styling */
.glassmorphic-form {
  background: rgba(255, 255, 255, 0.1); /* Semi-transparent white background */
  border-radius: 15px;
  padding: 20px;
  backdrop-filter: blur(10px); /* Background blur effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Slight shadow for better effect */
  animation: pulse 3s infinite; /* Add a pulse animation */
}

.whatsapp-button:hover {
  background-color: #1da851; /* Darker green on hover */
}

/* Input and select styling */
input, select {
  border: none;
  border-radius: 8px;
  padding: 10px;
  margin-top: 5px;
  background: rgba(255, 255, 255, 0.2); /* Slightly transparent background */
  backdrop-filter: blur(5px); /* Slight background blur */
}

input:focus, select:focus {
  outline: none;
}

/* Form and button styling */
button {
  background: #007bff; /* Blue color for the button */
  border: none;
  color: white;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Glassmorphic result styling */
.glassmorphic-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1); /* Semi-transparent white background */
  border-radius: 15px;
  padding: 20px;
  backdrop-filter: blur(10px); /* Background blur effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Slight shadow for better effect */
  margin-top: 20px;
  animation: pulse 3s infinite; /* Add a pulse animation */
  max-width: 90%; /* Responsive max width */
  width: 400px; /* Fixed width */
  text-align: center; /* Center-align text */
}

/* QR code styling */
.glassmorphic-result .qr-code {
  margin: 20px 0; /* Add spacing around the QR code */
  max-width: 100%; /* Ensure QR code is responsive */
}

/* Button styling */
.btn-primary {
  background: #007bff; /* Blue color for the button */
  border: none;
  color: white;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.btn-primary:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .glassmorphic-result {
    width: 90%; /* Full width on smaller screens */
    padding: 15px; /* Reduced padding */
  }

  .btn-primary {
    font-size: 14px; /* Smaller font size on smaller screens */
    padding: 8px 16px; /* Adjust button padding */
  }
}

